<template>
  <div class="wrapper">
    <div class="scancode">
      <img src="@/assets/images/scan-code.jpg" alt="" class="scan-code" />
      <div class="scan-tip">扫码打开微信小程序</div>
    </div>
    <div class="banner-wrapper">
      <el-carousel
        ref="carousel"
        :height="bannerHeight"
        trigger="click"
        arrow="never"
      >
        <el-carousel-item
          class="center"
          v-for="(item, index) of bannerList"
          :key="index"
        >
          <img
            :src="item.path"
            ref="bannerImg"
            v-if="!item.link"
            style="width: 100%"
            @load="imgLoad"
          />
          <!-- <el-image
            ref="bannerImg"
            v-if="!item.link"
            style="height: 100%;"
            fit="cover"
            :src="item.path"
          ></el-image> -->
          <a v-else :href="item.link" target="_blank">
            <img
              :src="item.path"
              ref="bannerImg"
              style="width: 100%"
              @load="imgLoad"
            />
            <!-- <el-image
              ref="bannerImg"
              style="height: 100%;"
              fit="cover"
              :src="item.path"
            ></el-image> -->
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="container">
      <div class="part1">
        <!-- <img src="@/assets/images/index_02.png" alt="">
        <img src="@/assets/images/index_03.png" alt="">
        <img src="@/assets/images/index_04.png" alt="">
        <img src="@/assets/images/index_05.png" alt="">
        <img src="@/assets/images/index_06.png" alt=""> -->
        <img src="@/assets/images/index11.jpg" alt="" />
        <img src="@/assets/images/index22.jpg" alt="" />
        <img src="@/assets/images/index33.jpg" alt="" />
      </div>
      <!-- <div class="block-1">
        <p class="title">产品服务</p>
        <div class="block-wrapper">
          <div class="block-item">
            <div class="left">
              <img src="@/assets/images/home_img1.png" alt="" />
            </div>
            <div class="right">
              <p class="title-1">艺术品数字化</p>
              <p class="content">
                赋予每件非同质化艺术品唯一的数字身份。<br />保障实物与数字身份一一对应。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="block-2">
        <div class="block-item">
          <div class="left-1">
            <p class="title-1">全生命周期管理</p>
            <p class="content">
              使用区块链记录艺术品全生命周期的重要信息，记录公开透明且不可篡改，覆盖生产、发行、流通等多个环节。
            </p>
          </div>
          <div class="right-1">
            <img src="@/assets/images/home_img2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="block-3">
        <p class="title second">平台价值</p>
        <div class="menu-wrapper">
          <menu-bar
            v-for="(item, index) of menuList"
            :item="item"
            :key="index"
          ></menu-bar>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import menuBar from "./component/menuBar";
import { getPcBannerList } from "@/api/index";
export default {
  name: "Index",
  components: {
    menuBar,
  },
  data() {
    return {
      bannerHeight: "0px",
      height: (((document.body.scrollWidth - 6) * 900) / 1920).toFixed(),
      menuList: [
        {
          icon: require("../../assets/images/index_icon1.png"),
          title: "科技赋能 推动产业升级",
          content:
            "通过区块链、3D高清扫描、AI识别等前沿技术，建立科学的艺术品全生命周期管理体系，赋能传统艺术品产业数字化转型升级",
        },
        {
          icon: require("../../assets/images/index_icon2.png"),
          title: "信息透明 提升市场活力",
          content:
            "全程信息公开透明可减少艺术品市场对中间人的依赖现象，提高艺术品的流通性和可拓展性，促进市场规范发展",
        },
        {
          icon: require("../../assets/images/index_icon3.png"),
          title: "信用背书 保障多方权益",
          content:
            "平台提供无可争辩的生产发行信息和鉴定流转记录，解决传统艺术品领域由于信息不对称导致的信任问题，保障创作者、所有者的权益及艺术品的价值",
        },
      ],
      bannerList: [],
    };
  },
  mounted() {
    this.getPcBannerList();
    this.$refs.carousel.handleMouseEnter = () => {};
    window.addEventListener(
      "resize",
      () => {
        this.imgLoad();
      },
      false
    );
  },
  methods: {
    async getPcBannerList() {
      let response = await getPcBannerList();
      if (response.code === 200) {
        this.bannerList = response.data;
      } else {
        return false;
      }
      this.imgLoad();
      console.log(response);
    },
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight =
          this.$refs.bannerImg && this.$refs.bannerImg.length
            ? this.$refs.bannerImg[0].height + "px"
            : "0px";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: auto;
  .banner-wrapper {
    width: 100%;
    // height: 920px;
    // background: #000000;

    /deep/.el-carousel__indicators--horizontal {
      bottom: 45px !important;
    }
    /deep/.el-carousel__indicator--horizontal {
      padding: 0 4px !important;
    }
    /deep/.el-carousel__button {
      height: 10px !important;
      border-radius: 5px;
      opacity: 1;
      // background-color: #ff9100 !important;
    }
    /deep/.el-carousel__indicator button {
      width: 10px !important;
    }
    /deep/.el-carousel__indicator.is-active button {
      background-color: #ff9100 !important;
      // width: 24px!important;
    }
  }
  .container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    .block-3 {
      overflow: hidden;
      background-image: url("~@/assets/images/home_bg3.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .menu-wrapper {
      width: 1200px;
      height: auto;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    .block-1 {
      background-image: url("~@/assets/images/home_bg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 60px;
    }
    .block-2 {
      padding-top: 30px;
      padding-bottom: 30px;
      background-image: url("~@/assets/images/home_bg2.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      .right-1 {
        width: 450px;
        height: 301px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .left-1 {
        width: 600px;
        padding-left: 92px;
        padding-right: 66px;
        .title-1 {
          font-size: 24px;
          margin-bottom: 20px;
          color: #333333;
          font-weight: 500;
        }
        .content {
          font-size: 14px;
          font-weight: 300;
          color: #828282;
        }
      }
    }
    .block-wrapper {
      width: 1200px;
      margin: 0 auto;
      // padding-bottom: 30px;
    }
    .block-item {
      width: 1200px;
      // height: 400px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      .right {
        width: 450px;
        .title-1 {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 20px;
          color: #333333;
        }
        .content {
          font-size: 14px;
          color: #828282;
          font-weight: 300;
        }
      }
      .left {
        width: 480px;
        height: 320px;
        margin-right: 120px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .title {
      width: 1200px;
      margin: 0 auto 60px;
      font-size: 32px;
      font-weight: 500;
      text-align: center;
      color: #000000;
      position: relative;
      z-index: 5;
      &.second {
        margin-top: 60px;
        margin-bottom: 30px;
      }
      &::after {
        content: "";
        width: 140px;
        height: 11px;
        background-color: #ff9100;
        opacity: 0.5;
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
        bottom: 8px;
        margin: auto;
      }
    }
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
}
.scancode {
  position: fixed;
  right: -96px;
  font-size: 12px;
  z-index: 999999999999;
  bottom: 30px;
  cursor: pointer;
  transition: all 0.5s;
  background: #fff;
  width: 199px;
  height: 245px;
  border-radius: 20px;
  overflow: hidden;
  padding: 10px;
  &:hover {
    right: 0px;
  }
  .scan-code {
    width: 178px;
    height: 178px;
    display: block;
    margin: 0 auto;
  }
  .scan-tip {
    text-align: center;
    margin-top: 14px;
    font-size: 14px;
    color: #333333;
  }
}
.part1 {
  // width: 1400px;
  // margin: 0 auto;
  width: 100%;
  img {
    display: block;
    width: 100%;
    margin-bottom: 0;
  }
}
</style>

<template>
  <div>
    <div class="block">
      <div class="card">
        <div class="wrapper">
          <img :src="item.icon" alt="" />
          <p class="title">{{ item.title }}</p>
          <p class="content">{{ item.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  width: 280px;
  margin: 0 40px;
  min-height: 316px;
  .card{
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    min-height: 316px;
  }
  .wrapper {
    .title {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      min-height: 22px;
      line-height: 22px;
      color: #333;
      margin-bottom: 11px;
    }
    .content {
      width: 100%;
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      color: #828282;
    }
    img {
      width: 72px;
      height: 72px;
      margin-bottom: 32px;
    }
  }
}
</style>